<template>
  <div class="overflowYHidden">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang='scss' scoped>
.overflowYHidden {
  height: 100%;
  padding-right: 17px;
  margin-right: -17px;
  overflow-y: scroll;
}
</style>