<template>
  <div class="sunmitScanning">
    <div class="main">
      <overflowYHidden>
        <van-form>
          <van-cell title="借用人员" :value="userInfo.name" />
          <van-cell title="使用科室" :value="userInfo.tenantName" />
          <van-field
            v-model="room"
            label="借用房间"
            placeholder="请输入借用房间"
          />
          <van-field
            v-model="bed"
            label="病例号/床号"
            placeholder="请输入病例号/床号"
          />
          <van-field
              v-model="userInfo.phone"
              label="手机号码"
              type="tel"
              placeholder="请输入手机号码"
          />
        </van-form>
      </overflowYHidden>
    </div>
    <div class="footer" @click="startUsing">确定</div>
  </div>
</template>

<script>
import overflowYHidden from "@/components/overflowYHidden";
export default {
  components: { overflowYHidden },
  data() {
    return {
      userInfo: {},
      orderId: "",
      room: "",
      bed: "",
    };
  },
  created() {
    this.userInfo = this.$store.getters.userInfo;
    this.orderId = this.$route.query.orderId;
  },
  methods: {
    startUsing() {
      this.$dialog.confirm({
        message: `确定提交使用？`,
        beforeClose: (action, done) => {
          if (action === "confirm") {
            this.$api.deviceBorrowModule
              .startUsing({
                id: this.orderId,
                room: this.room,
                bed: this.bed,
              })
              .then((res) => {
                localStorage.setItem('allDeviceUpdate', new Date().getTime())
                this.$toast.success(
                  '操作成功'
                );
                done();
                localStorage.setItem('updateDeviceBorrow', new Date().getTime())
                this.postMessage({ type: 'refreshRemind' })
                this.$back();
              })
              .catch((e) => {
                done();
                this.$toast.fail(e.data.msg);
              });
          } else {
            done();
          }
        },
      });
    },
  },
};
</script>
<style lang='scss' scoped>
@import "@styles/variables.scss";
.sunmitScanning {
  height: 100%;
  padding: 10px;
  .main {
    height: calc(100% - 60px);
    overflow: hidden;
  }
  .footer {
    margin-top: 12px;
    margin-bottom: 12px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #3e73fb;
    border-radius: 18px;
    color: #ffffff;
    font-size: 16px;
  }
}
::v-deep .van-field__control {
  text-align: right;
}
</style>